import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { BasketService } from '../services';

export const checkoutGuard: CanActivateFn = () => {
  const router = inject(Router);
  const basketService = inject(BasketService);

  if (!basketService.inProgress()) {
    return router.createUrlTree(['/events']);
  }

  return true;
};

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

@Component({
  selector: 'app-reservation-timer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './reservation-timer.component.html',
  styleUrl: './reservation-timer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationTimerComponent {
  duration = input.required<number>();
  remaining = input.required<number>();

  width = computed(() => {
    const percent = ((this.remaining() ?? 0) / this.duration()) * 100;
    return percent + '%';
  });
}

<div class="flex items-center justify-center relative">
  <input
    [id]="name()"
    [name]="name()"
    [type]="isPasswordVisible() ? 'text' : 'password'"
    [formControl]="control()"
    [placeholder]="placeholder()"
    class="flex-grow h-12 w-full py-2 px-4 border-2 rounded-md bg-form-field-color bg-opacity-10 text-form-field-color focus:border-primary focus:outline-none border-gray-300"
    [style.height]="height()"
    autocomplete="off"
  />
  <button
    type="button"
    class="icon-button text-form-field-label px-1 absolute right-1"
    aria-label="Toggle password visibility"
    (click)="togglePasswordVisibility()"
    [disabled]="isDisabled()"
  >
    <i class="fa-regular fa-eye{{ isPasswordVisible() ? '-slash' : '' }} mx-2"></i>
  </button>
</div>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';

import { AssignTicketsComponent } from '@features/bookings';
import { BasketService } from '../../services';

@Component({
  selector: 'app-payment-complete',
  standalone: true,
  imports: [CommonModule, AssignTicketsComponent],
  templateUrl: './payment-complete.component.html',
  styleUrl: './payment-complete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCompleteComponent {
  #basketService = inject(BasketService);

  protected route = inject(ActivatedRoute);

  protected loaded = signal(false);

  protected booking = toSignal(this.#basketService.validateCheckout().pipe(finalize(() => this.loaded.set(true))));
}

import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { catchError, finalize, from, tap } from 'rxjs';

import { SnackBar } from '@components/snack-bar';
import { SpinnerComponent } from '@components/spinner';
import { StaticNotificationComponent } from '@components/static-notification';
import { BackButtonComponent } from '@components/back-button';

import { EventHeaderComponent } from '../../components';
import { PurchasedTicket, defaultEventTitle } from '../../models';
import { BookingsService } from '../../services';

@Component({
  selector: 'app-ticket',
  standalone: true,
  imports: [BackButtonComponent, RouterLink, SpinnerComponent, EventHeaderComponent, StaticNotificationComponent],
  templateUrl: './ticket.component.html',
  styleUrl: './ticket.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketComponent implements OnInit {
  #route = inject(ActivatedRoute);
  protected bookingService = inject(BookingsService);
  #router = inject(Router);
  #snackBar = inject(SnackBar);
  defaultEventTitle = defaultEventTitle;

  protected ticket = signal<PurchasedTicket | null>(null);
  protected loadingTicket = signal<boolean>(true);
  protected bookingId = signal<string>('');

  ngOnInit() {
    this.bookingId.set(this.#route.snapshot.params['bookingId']);
    const ticketId: string = this.#route.snapshot.params['ticketId'];

    this.bookingService
      .getTicket(this.bookingId(), ticketId)
      .pipe(
        tap((ticket) => this.ticket.set(ticket)),
        catchError(() => {
          this.#snackBar.error('An error occurred retrieving your booking details');
          return from(this.#router.navigate(['/', 'bookings']));
        }),
        finalize(() => this.loadingTicket.set(false)),
      )
      .subscribe();
  }

  protected qrImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = '/assets/images/bongos-logo-pattern.png';
    imgElement.title = 'QR Code Image failed to load';
  }
}

<div class="page">
  <section class="min-h-[50dvh] md:min-h-[50vh] w-full p-3 md:py-10 md:px-16" aria-labelledby="checkout-title">
    <h2 id="checkout-title" class="text-4xl md:text-6xl font-bold pb-6 md:pb-12">Checkout</h2>
    @if (basket(); as basket) {
    <div class="flex flex-col md:flex-row gap-6 md:gap-[8%]">
      <div class="md:w-1/2">
        <app-reserved-tickets [tickets]="basket.tickets"></app-reserved-tickets>
      </div>

      <div class="md:w-1/2 flex flex-col gap-6">
        @if (basket.tickets.length > 0) {
        <app-reservation-timer [duration]="duration()" [remaining]="remainingTime()"></app-reservation-timer>

        <app-payment-options [basket]="basket"></app-payment-options>
        }
      </div>
    </div>
    } @else {
    <div class="flex items-center gap-3">
      <app-spinner [size]="24"></app-spinner>
      <span>Getting basket...</span>
    </div>
    }
  </section>
</div>
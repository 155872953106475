<div class="flex items-center justify-center relative">
  <input
    [id]="name()"
    [name]="name()"
    [type]="type()"
    [formControl]="control()"
    [placeholder]="placeholder()"
    class="flex-grow h-12 w-full py-2 px-4 border-2 rounded-md placeholder:text-sm {{
      backgroundClasses()
    }} text-form-field-color focus:border-primary focus:outline-none border-form-field-border"
    [style.height]="height()"
    [ngStyle]="{ 'text-transform': isCapitalized() ? 'uppercase' : 'none' }"
    autocomplete="off"
  />
  @if (iconButton(); as icon) {
    <button
      type="button"
      class="icon-button text-white px-1 absolute right-1"
      [disabled]="isDisabled()"
      (click)="iconButtonClick()"
    >
      <i class="{{ icon }}"></i>
    </button>
  }
</div>

import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { StaticNotificationComponent } from '@components/static-notification';
import { GroupedTicket } from '@features/checkout';
import { TicketOptions } from '@features/common';

import { Booking, BookingTicket } from '../../models';
import { defaultEventTitle } from '../../models';
import { EventHeaderComponent } from '../event-header';

@Component({
  selector: 'app-booking-card',
  standalone: true,
  imports: [CurrencyPipe, RouterLink, StaticNotificationComponent, EventHeaderComponent],
  templateUrl: './booking-card.component.html',
  styleUrl: './booking-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingCardComponent {
  booking = input.required<Booking>();
  defaultEventTitle = defaultEventTitle;

  pastEvent = computed(() => {
    return this.booking().event.date < new Date();
  });

  getTickets(booking: Booking): GroupedTicket[] {
    const groups = booking.basket.tickets.reduce<GroupedTicket[]>((grouped, ticket) => {
      const group = grouped.find((group) => group.id === ticket.ticket_type.id);
      if (group) {
        group.count++;
        group.price += this.getTicketPrice(ticket);
      } else {
        grouped.push({
          id: ticket.ticket_type.id,
          name: ticket.ticket_type.ticket_type_option.name,
          price: this.getTicketPrice(ticket),
          bookingFee: ticket.ticket_type.booking_fee,
          count: 1,
        });
      }

      return grouped;
    }, []);

    return groups;
  }

  getTicketPrice(ticket: BookingTicket) {
    return ticket.ticket_option === TicketOptions.fullTicket
      ? ticket.ticket_type.ticket_price + ticket.ticket_type.gambling_stake
      : ticket.ticket_option === TicketOptions.eventOnly
        ? ticket.ticket_type.ticket_price
        : ticket.ticket_type.gambling_stake;
  }

  isAssignmentIncomplete(booking: Booking) {
    return booking.basket.tickets.some((ticket) => !ticket.ticket_holder_email);
  }
}

import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, finalize, from, map, of, switchMap, tap } from 'rxjs';
import {
  Appearance,
  ConfirmPaymentData,
  CssFontSource,
  CustomFontSource,
  loadStripe,
  Stripe,
  StripeElements,
  StripePaymentElement,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';

import { SnackBar } from '@components/snack-bar';
import { SpinnerComponent } from '@components/spinner';
import { StaticNotificationComponent } from '@components/static-notification';

import { environment } from '@environment/environment';
import { EventHeaderComponent } from '@features/bookings';

import { Basket, GroupedTicket } from '../../models';
import { BasketService } from '../../services';

@Component({
  selector: 'app-payment-options',
  standalone: true,
  imports: [CurrencyPipe, SpinnerComponent, StaticNotificationComponent, EventHeaderComponent],
  templateUrl: './payment-options.component.html',
  styleUrl: './payment-options.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentOptionsComponent implements OnInit {
  #basketService = inject(BasketService);
  #destroyRef = inject(DestroyRef);
  #snackBar = inject(SnackBar);

  basket = input.required<Basket>();

  loadingStripe = signal<boolean>(false);
  stripe: Stripe | null = null;
  elements: StripeElements | null = null;
  paymentElement: StripePaymentElement | null = null;

  firstTicket = computed(() => this.basket().tickets[0]);

  staticError = signal<string | undefined>('');

  groupedTickets = computed((): GroupedTicket[] | null => {
    if ((this.basket().tickets ?? []).length === 0) {
      return null;
    }

    const groups = this.basket().tickets.reduce<GroupedTicket[]>((grouped, ticket) => {
      const group = grouped.find((group) => group.id === ticket.ticket_type.id);
      if (group) {
        group.count++;
        group.price += ticket.price;
      } else {
        grouped.push({
          id: ticket.ticket_type.id,
          name: ticket.ticket_type.ticket_type_option.name,
          price: ticket.price,
          bookingFee: ticket.ticket_type.booking_fee,
          count: 1,
        });
      }

      return grouped;
    }, []);

    return groups;
  });

  totalBookingFee = computed(() =>
    this.basket().tickets.reduce((acc, ticket) => acc + ticket.ticket_type.booking_fee, 0),
  );

  totalPrice = computed(
    () => this.basket().tickets.reduce((acc, ticket) => acc + ticket.price, 0) + this.totalBookingFee(),
  );
  protected formComplete = signal<boolean>(false);
  protected processingPayment = signal<boolean>(false);

  ngOnInit() {
    this.loadingStripe.set(true);
    this.staticError.set('');

    from(loadStripe(environment.stripePublicKey))
      .pipe(
        tap((stripe) => (this.stripe = stripe)),
        switchMap(() => this.#basketService.getPaymentIntent()),
        map((response) => response.client_secret),
        tap((clientSecret) => {
          const options: StripePaymentElementOptions = {
            business: { name: "Bongo's Bingo" },
            paymentMethodOrder: ['apple_pay', 'google_pay', 'card', 'klarna'],
          };
          const appearance: Appearance = {
            variables: {
              fontSizeBase: '16px',
              colorBackground: '#393939',
              colorText: '#ffffff',
              colorDanger: '#eb5757',
              focusBoxShadow: 'none',
            },
            rules: {
              '.Input:focus': {
                border: '1px solid #ff0f95',
                boxShadow: '0 0 0 1px #ff0f95',
              },
              '.Input': {
                border: '1px solid #d7d7d7',
              },
            },
          };
          const fonts: (CustomFontSource | CssFontSource)[] = [
            {
              family: 'Tenso',
              src: `url(${window.location.host}/assets/fonts/Tenso-Regular.ttf) format("truetype")`,
            },
          ];

          this.elements = this.stripe?.elements({ clientSecret, appearance, fonts }) ?? null;

          if (this.elements) {
            this.paymentElement = this.elements?.create('payment', options) ?? null;
            this.paymentElement?.mount('#payment-element');
            this.paymentElement.on('change', (event) => this.formComplete.set(event.complete));
          }
        }),
        catchError((error) => {
          this.#snackBar.error(`There was an error with the payment provider.  Please refresh and try again. ${error}`);
          return of(null);
        }),
        finalize(() => {
          this.loadingStripe.set(false);
        }),
      )
      .subscribe();
  }

  handleSubmit(event: SubmitEvent) {
    event.preventDefault();

    if (!this.stripe || !this.elements) {
      return;
    }

    const confirmParams: ConfirmPaymentData = {
      return_url: `${window.location.origin}/checkout/complete`,
    };

    this.staticError.set('');
    this.processingPayment.set(true);
    from(this.stripe.confirmPayment({ elements: this.elements, confirmParams }))
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        catchError((error) => {
          this.staticError.set(error);
          return of(null);
        }),
        finalize(() => this.processingPayment.set(false)),
      )
      .subscribe((result) => {
        if (result?.error) {
          this.staticError.set(result.error.message);
        }
      });
  }
}

import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

@Component({
  selector: 'app-out-of-time-dialog',
  standalone: true,
  imports: [],
  templateUrl: './out-of-time-dialog.component.html',
  styleUrls: ['./out-of-time-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutOfTimeDialogComponent {
  dialogRef = inject(DialogRef<OutOfTimeDialogComponent>);

  closeDialog() {
    this.dialogRef.close();
  }
}

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';

import { AuthService } from '../services';

export const authGuard: CanActivateFn = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const auth = inject(AuthService);
  const router = inject(Router);

  if (auth.authenticated()) {
    return of(true);
  }

  return router.createUrlTree(['/', 'login'], { queryParams: { redirect: state.url } });
};

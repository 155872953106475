import { provideHttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { environment } from '@environment/environment';
import { provideApi } from '@features/api';
import { AuthService, httpTokenInterceptor, JWTService } from '@features/auth';
import { httpDeviceInterceptor } from '@features/device';
import { httpErrorInterceptor } from '@features/errors';
import { routes } from './app.routes';

export function jwtInitialiser(jwtService: JWTService, authService: AuthService) {
  return () => (jwtService.token ? authService.populate() : authService.createGuest());
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withInMemoryScrolling({ scrollPositionRestoration: 'top' })),
    provideHttpClient(),
    provideApi(environment.api_url, {
      interceptors: [httpTokenInterceptor, httpDeviceInterceptor, httpErrorInterceptor],
    }),
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'GBP' },
    {
      provide: APP_INITIALIZER,
      useFactory: jwtInitialiser,
      multi: true,
      deps: [JWTService, AuthService],
    },
    importProvidersFrom([BrowserAnimationsModule]),
  ],
};

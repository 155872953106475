@if (basket().tickets.length > 0) {
  <div class="w-full p-3 md:px-6 md:py-4 flex flex-col justify-start bg-neutral-800">
    <div class="pt-5">
      <app-event-header
        [name]="basket().tickets[0].event.additional_title"
        [date]="firstTicket().event.event_date"
        [venueName]="firstTicket().event.venue.name"
        [address]="firstTicket().event.venue.address"
      ></app-event-header>
    </div>

    <section class="pt-10 text-lg" aria-label="Order Summary">
      <div class="grid grid-cols-[auto_1fr_auto] divide-y divide-gray-dark text-lg">
        @for (ticket of groupedTickets(); track $index) {
          <div class="border-t border-t-gray-dark py-2 pr-5">{{ ticket.name }}:</div>
          <div class="flex flex-col justify-center h-full pr-3">
            <div class="py-2">{{ ticket.count }}</div>
          </div>
          <div class="flex flex-col justify-center h-full">
            <div class="py-2 text-right">{{ ticket.price | currency }}</div>
          </div>
        }

        <div class="py-2 pr-5 border-t border-t-gray-dark">Booking Fee:</div>
        <div class="flex flex-col justify-center h-full pr-3">
          <div class="py-2">{{ basket().tickets.length }}</div>
        </div>
        <div class="flex flex-col justify-center h-full">
          <div class="py-2 text-right">{{ totalBookingFee() | currency }}</div>
        </div>

        <div class="col-span-2 pt-4 pr-2">Total:</div>
        <div class="flex flex-col justify-center h-full">
          <div class="pt-4 text-right">{{ totalPrice() | currency }}</div>
        </div>
      </div>
    </section>

    <h2 class="text-3xl font-bold pt-10">Complete Purchase</h2>

    <div class="pt-8 flex flex-col items-center gap-2">
      @if (loadingStripe()) {
        <div class="flex items-center gap-3 w-full">
          <app-spinner [size]="20"></app-spinner>
          <span>Getting payment options...</span>
        </div>
      }
      <form (submit)="handleSubmit($event)" [class.hidden]="loadingStripe()" class="w-full">
        <section id="payment-element" aria-label="Enter your payment details below"></section>

        @if (staticError()) {
          <div class="pt-3">
            <app-static-notification notificationType="error">{{ staticError() }}</app-static-notification>
          </div>
        }
        <button
          type="submit"
          class="primary-button h-14 w-full mt-8"
          [disabled]="this.loadingStripe() || processingPayment() || !formComplete()"
        >
          @if (processingPayment()) {
            <app-spinner></app-spinner>
          } @else {
            <span>Pay</span>
          }
        </button>
      </form>
    </div>
  </div>
}

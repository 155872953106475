<section class="grid grid-cols-[auto_1fr] items-center gap-3 font-bold {{ class() }}">
  <h2 class="col-span-2 text-3xl">{{ name() }}</h2>

  <i class="fa-regular fa-calendar-days text-2xl justify-self-center"></i>
  <span class="text-2xl" aria-label="Event date">{{ date() | date: 'longDate' }}</span>

  <i class="fa-regular fa-location-dot text-2xl justify-self-center"></i>
  <span class="text-base" aria-label="Event address">{{ venueName() }} - {{ address() }}</span>

  @if (holderName()) {
    <i class="fa-regular fa-circle-user text-2xl justify-self-center"></i>
    <span class="text-base" aria-label="Ticket holders name">{{ holderName() }}</span>
  }

  @if (holderEmail()) {
    <i class="fa-regular fa-circle-envelope text-2xl justify-self-center"></i>
    <span class="text-base" aria-label="Ticket holders email">{{ holderEmail() }}</span>
  }
</section>

import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-event-header',
  standalone: true,
  imports: [DatePipe],
  templateUrl: './event-header.component.html',
  styleUrl: './event-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventHeaderComponent {
  name = input.required<string>();
  date = input.required<Date>();
  venueName = input.required<string>();
  address = input.required<string>();
  holderName = input<string>();
  holderEmail = input<string>();
  class = input<string>();
}

import { Routes } from '@angular/router';
import { authGuard, noAuthGuard } from '@features/auth';
import { checkoutGuard } from '@features/checkout/guards';

export const routes: Routes = [
  // HOME
  {
    path: '',
    title: "Bongo's Bingo",
    pathMatch: 'full',
    loadComponent: () => import('@features/home').then((c) => c.HomeComponent),
  },
  {
    path: 'privacy-policy',
    title: "Bongo's Bingo - Privacy Policy",
    loadComponent: () => import('@features/home').then((c) => c.PrivacyPolicyComponent),
  },
  {
    path: 'terms-and-conditions',
    title: "Bongo's Bingo - Terms & Conditions",
    loadComponent: () => import('@features/home').then((c) => c.TermsConditionsComponent),
  },
  {
    path: 'what-is-bongos-bingo',
    title: "Bongo's Bingo - What is Bongo's Bingo",
    loadComponent: () => import('@features/home').then((c) => c.WhatIsBbComponent),
  },
  // IDENTITY
  {
    path: 'register',
    title: "Bongo's Bingo - Register",
    loadComponent: () => import('@features/registration').then((c) => c.RegistrationComponent),
    canActivate: [noAuthGuard],
  },
  {
    path: 'activate',
    title: "Bongo's Bingo - Activate",
    loadComponent: () => import('@features/registration').then((c) => c.ActivateComponent),
    canActivate: [noAuthGuard],
  },
  {
    path: 'login',
    title: "Bongo's Bingo - Login",
    loadComponent: () => import('@features/login').then((c) => c.LoginComponent),
    canActivate: [noAuthGuard],
  },
  {
    path: 'reset-password-request',
    title: "Bongo's Bingo - Forgot Password",
    loadComponent: () => import('@features/password').then((c) => c.ResetPasswordRequestComponent),
    canActivate: [noAuthGuard],
  },
  {
    path: 'forgot-password',
    title: "Bongo's Bingo - Reset Password",
    loadComponent: () => import('@features/password').then((c) => c.ResetPasswordComponent),
    canActivate: [noAuthGuard],
  },

  // EVENTS
  {
    path: 'events',
    title: "Bongo's Bingo - Events",
    loadComponent: () => import('@features/events').then((c) => c.EventsComponent),
  },
  {
    path: 'events/:id',
    title: "Bongo's Bingo - Event",
    loadComponent: () => import('@features/events').then((c) => c.EventDetailsComponent),
  },
  {
    path: 'checkout',
    canActivate: [authGuard],
    children: [
      {
        path: '',
        canActivate: [checkoutGuard],
        title: "Bongo's Bingo - Checkout",
        loadComponent: () => import('@features/checkout').then((c) => c.CheckoutComponent),
      },
      {
        path: 'complete',
        title: "Bongo's Bingo - Checkout Complete",
        loadComponent: () => import('@features/checkout').then((c) => c.PaymentCompleteComponent),
      },
    ],
  },

  // PROFILE
  {
    path: 'profile',
    canActivate: [authGuard],
    children: [
      {
        path: '',
        title: "Bongo's Bingo - Profile",
        loadComponent: () => import('@features/profile').then((c) => c.ProfileComponent),
      },
      // DETAILS
      {
        path: 'details',
        children: [
          {
            path: '',
            title: "Bongo's Bingo - Your Details",
            loadComponent: () => import('@features/profile').then((c) => c.YourDetailsComponent),
          },
          {
            path: 'update-password',
            title: "Bongo's Bingo - Update Password",
            loadComponent: () => import('@features/profile').then((c) => c.UpdatePasswordComponent),
          },
          {
            path: 'update-email',
            title: "Bongo's Bingo - Update Email",
            loadComponent: () => import('@features/profile').then((c) => c.UpdateEmailComponent),
          },
        ],
      },
    ],
  },

  // BOOKINGS
  {
    path: 'bookings',
    title: "Bongo's Bingo - Bookings",
    canActivate: [authGuard],
    loadComponent: () => import('@features/bookings').then((c) => c.BookingsComponent),
  },
  {
    path: 'bookings/:id',
    title: "Bongo's Bingo - Assign Tickets",
    canActivate: [authGuard],
    loadComponent: () => import('@features/bookings').then((c) => c.BookingComponent),
  },
  {
    path: 'bookings/:bookingId/tickets/:ticketId',
    title: "Bongo's Bingo - Ticket",
    canActivate: [authGuard],
    loadComponent: () => import('@features/bookings').then((c) => c.TicketComponent),
  },

  { path: '**', redirectTo: '', pathMatch: 'full' },
];

import { DialogRef } from '@angular/cdk/dialog';
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { filter, finalize, from, switchMap, timer } from 'rxjs';

import { AuthService } from '@features/auth';
import { BasketService } from '@features/checkout';
import { BusyOverlayComponent } from '@components/busy-overlay';

import { slideInOutAnimation } from '../animations';

@Component({
  selector: 'app-navigation-drawer',
  standalone: true,
  imports: [RouterLink, BusyOverlayComponent],
  templateUrl: './navigation-drawer.component.html',
  styleUrl: './navigation-drawer.component.scss',
  animations: [slideInOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationDrawerComponent implements OnInit, AfterViewInit {
  #dialogRef = inject(DialogRef);
  protected auth = inject(AuthService);
  protected basket = inject(BasketService);

  #destroyRef = inject(DestroyRef);
  #router = inject(Router);

  protected animationState = signal<string>('out');
  protected overlayMessage = signal<string>('');
  protected redirectUrl = signal('/');

  ngOnInit() {
    this.redirectUrl.set(this.#router.url);

    this.#router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.closeDialog();
    });
  }

  ngAfterViewInit() {
    this.animationState.set('in');
  }

  protected closeDialog() {
    this.animationState.set('out');
    timer(200).subscribe(() => {
      this.#dialogRef.close();
      window.scrollTo(0, 0);
    });
  }

  protected logout() {
    this.overlayMessage.set('Logging out...');

    this.auth
      .logout()
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        switchMap(() => from(this.#router.navigate(['/']))),
        finalize(() => {
          this.overlayMessage.set('');
          this.closeDialog();
        }),
      )
      .subscribe();
  }
}

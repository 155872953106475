import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { TicketOptions } from '@features/common';
import { BasketTicket, GroupedTicket } from '../../models';

@Component({
  selector: 'app-reserved-tickets',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './reserved-tickets.component.html',
  styleUrl: './reserved-tickets.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservedTicketsComponent {
  tickets = input.required<BasketTicket[]>();

  groupedTickets = computed((): GroupedTicket[] | null => {
    if ((this.tickets() ?? []).length === 0) {
      return null;
    }

    const groups = this.tickets().reduce((grouped: GroupedTicket[], ticket) => {
      const group = grouped.find((group) => group.id === ticket.ticket_type.id + ticket.ticket_option);
      if (group) {
        group.count++;
      } else {
        const optionName = this.getTicketOptionDescription(ticket.ticket_option);
        grouped.push({
          id: ticket.ticket_type.id + ticket.ticket_option,
          name: `${ticket.ticket_type.ticket_type_option.name}${optionName ? ` (${optionName})` : ''}`,
          price: ticket.price,
          count: 1,
        });
      }
      return grouped;
    }, []);

    return groups;
  });

  private getTicketOptionDescription(ticketOption: string) {
    return ticketOption === TicketOptions.eventOnly
      ? 'event only'
      : ticketOption === TicketOptions.gamblingOnly
        ? 'gambling only'
        : '';
  }
}

import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject } from '@angular/core';
import { interval, map, timeInterval } from 'rxjs';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { SpinnerComponent } from '@components/spinner';

import {
  OutOfTimeDialogComponent,
  PaymentOptionsComponent,
  ReservationTimerComponent,
  ReservedTicketsComponent,
} from '../../components';
import { BasketService } from '../../services';

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    ReservedTicketsComponent,
    ReservationTimerComponent,
    PaymentOptionsComponent,
  ],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutComponent {
  #basketService = inject(BasketService);
  #destroyRef = inject(DestroyRef);
  #router = inject(Router);
  #dialog = inject(Dialog);

  duration = computed(() => {
    const start = this.#basketService.basket()?.ticket_hold_start_time.getTime() ?? 0;
    const end = this.#basketService.basket()?.ticket_hold_end_time.getTime() ?? 0;
    return Math.max((end - start) / 1000, 0);
  });

  basket = computed(() => this.#basketService.basket());

  remainingTime = computed(() => Math.max(this.#initialRemainingTime() - this.#timer(), 0));

  #timer = toSignal(
    interval(1000).pipe(
      takeUntilDestroyed(this.#destroyRef),
      timeInterval(),
      map((timer) => timer.value),
    ),
    { initialValue: 0 },
  );

  #initialRemainingTime = computed(() => {
    const now = new Date().getTime();
    const end = this.#basketService.basket()?.ticket_hold_end_time.getTime() ?? 0;
    return (end - now) / 1000;
  });

  #outOfTime = computed(() => this.#timer() > 0 && this.remainingTime() <= 0);

  constructor() {
    effect(() => {
      if ((this.#basketService.basket()?.tickets ?? []).length > 0 && this.#outOfTime()) {
        this.openTimerFinishedDialog();
      }
    });
  }

  private openTimerFinishedDialog() {
    const dialogRef = this.#dialog.open(OutOfTimeDialogComponent, { disableClose: true });

    dialogRef.closed.subscribe(() => {
      this.#basketService.clear();
      this.#router.navigate(['/', 'events']);
    });
  }
}

<div [@slideInOut]="animationState()" class="w-full h-full bg-black text-white flex flex-col p-3">
  <div class="flex flex-col">
    <header class="relative mt-3">
      <div class="flex justify-center">
        <a [routerLink]="['/']" aria-label="Go to the Bongo's Bingo homepage">
          <img src="assets/images/logo.svg" class="w-40" alt="" />
        </a>
      </div>

      <button
        type="button"
        (click)="closeDialog()"
        class="absolute top-0 right-0 px-2 h-full"
        aria-label="Close navigation menu"
      >
        <i class="fa-solid fa-xmark text-3xl"></i>
      </button>
    </header>

    <nav class="flex flex-col">
      <div
        class="w-full pt-7 flex flex-col divide-y divide-neutral-700 border-b border-b-neutral-700 no-underline text-lg font-bold"
      >
        <a class="pl-2.5 py-5 cursor-pointer" [routerLink]="['/', 'events']" aria-label="Search for an event">EVENTS</a>
        <a class="pl-2.5 py-5 cursor-pointer" [routerLink]="['/']" aria-label="Who are the Hosts and Dancers?"
          >HOSTS & DANCERS</a
        >
        <a class="pl-2.5 py-5 cursor-pointer" [routerLink]="['/']" aria-label="What to expect at Bongo's Bingo"
          >WHAT IS BONGO'S?</a
        >
        <a class="pl-2.5 py-5 cursor-pointer" [routerLink]="['/']" aria-label="Frequently asked questions">FAQ'S</a>
        <a class="pl-2.5 py-5 cursor-pointer" [routerLink]="['/']" aria-label="Get in touch with Bongo's Bingo"
          >CONTACT</a
        >
      </div>

      <div class="flex flex-col items-start gap-4 pt-9">
        @if (auth.authenticated()) {
          <div class="w-full flex space-x-2">
            <a
              [routerLink]="['/', 'profile']"
              aria-label="Visit your profile page"
              class="flex-1 outline-button secondary"
              >Profile</a
            >
            @if (basket.inProgress()) {
              <a
                [routerLink]="['/checkout']"
                class="flex-1 flex flex-row gap-2 primary-button"
                aria-label="Go to checkout"
              >
                <i class="fa-solid fa-cart-shopping"></i>Basket
              </a>
            } @else {
              <button class="flex-1 primary-button" (click)="logout()" aria-label="Logout of Bongo's Bingo">
                Logout
              </button>
            }
          </div>
        } @else {
          <div class="w-full flex space-x-2">
            <a
              [routerLink]="['/', 'login']"
              [queryParams]="{ redirect: this.redirectUrl }"
              aria-label="Login to Bongo's Bingo"
              class="flex-1 primary-button"
              >Login</a
            >
            <a
              [routerLink]="['/', 'register']"
              aria-label="Register a new Bongo's Bingo account"
              class="flex-1 primary-button"
              >Register</a
            >
          </div>
        }
      </div>
    </nav>
  </div>
</div>

<app-busy-overlay [busy]="!!overlayMessage()">{{ overlayMessage() }}</app-busy-overlay>

<section class="w-full px-2 py-4 flex flex-col justify-start bg-neutral-800 p-2 md:p-10" aria-labelledby="basket-title">
  <h3 id="basket-title" class="text-2xl font-bold pb-3 md:pb-6">Basket</h3>
  @if (groupedTickets()) {
    @for (ticket of groupedTickets(); track $index) {
      <div class="flex items-center">
        <span class="flex-1 pr-1 md:text-lg md:font-medium md:pr-2"
          >{{ ticket.name }} - {{ ticket.price | currency }}</span
        >
        <span class="md:text-2xl md:font-bold">{{ ticket.count }}</span>
      </div>
    }
  } @else {
    <p>Looks like your basket is empty. Add tickets to your basket and come back!</p>
    <a [routerLink]="['/', 'events']" class="primary-button h-12 mt-6">Find an event</a>
  }
</section>

import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { DeviceService } from '../services';

export const httpDeviceInterceptor: HttpInterceptorFn = (req, next) => {
  const deviceService = inject(DeviceService);

  req = req.clone({
    setHeaders: {
      'X-Device-ID': deviceService.deviceId(),
      'X-Device-Name': navigator.userAgent,
    },
  });

  return next(req);
};

<div class="page">
  <div class="w-full px-2 py-4 md:px-24 md:py-12">
    @if (loadingTicket()) {
      <div class="h-[25dvh] md:h-[25vh] w-full flex items-center justify-center gap-2">
        <app-spinner></app-spinner>
        <span>Loading Ticket...</span>
      </div>
    }

    @if (ticket(); as ticket) {
      <div class="w-full flex flex-col items-center md:items-start">
        <div class="relative self-start">
          <a
            app-back-button
            [routerLink]="['/', 'bookings', bookingId()]"
            class="absolute top-0 left-0"
            aria-label="Go back to the booking details page"
          ></a>
        </div>

        <h1 class="text-5xl px-10 md:px-0 md:text-7xl font-bold">Your ticket</h1>

        <div class="flex md:flex-row flex-col pt-11 max-w-[350px] md:max-w-[1000px]">
          <div
            class="md:w-2/5 flex p-3 bg-white text-black items-center justify-center relative rounded-t-xl md:rounded-l-xl inverted-corner-qr"
          >
            <div
              class="h-full w-full md:h-auto md:w-auto p-2 md:p-3 rounded-2xl bg-gradient-to-r from-[rgba(238,79,156,1)] via-[rgba(204,0,255,0.78)] to-[rgba(0,87,255,1)]"
            >
              <div class="h-full w-full md:h-auto md:w-auto p-2 md:p-5 bg-white rounded-lg">
                <img
                  [src]="ticket.qr_image_url"
                  (error)="qrImageError($event)"
                  alt="Ticket QR Code"
                  class="h-full w-full md:h-auto md:w-auto aspect-square object-cover"
                />
              </div>
            </div>
          </div>

          <div
            class="md:w-3/5 relative flex items-center p-8 bg-white text-black rounded-b-xl md:rounded-r-xl inverted-corner-event"
          >
            <div class="text-[rgba(36,36,36,1)]">
              <app-event-header
                [name]="ticket.event.additional_title ? ticket.event.additional_title : defaultEventTitle"
                [date]="ticket.event.event_date"
                [venueName]="ticket.event.venue.name"
                [address]="ticket.event.venue.address"
                [holderName]="ticket.ticket_holder_name"
                [holderEmail]="ticket.ticket_holder_email"
              ></app-event-header>

              @if (!ticket.ticket_holder_email) {
                <div class="w-full md:pt-5 text-white">
                  <app-static-notification notificationType="error"
                    >This ticket must be assigned to someone to be valid</app-static-notification
                  >
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</div>

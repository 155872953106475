<section class="bg-repeat bg-cover bongos-background" aria-label="Subscribe to the new events mailing list">
  <form [formGroup]="form" (ngSubmit)="subscribeToNewsletter()">
    <fieldset [disabled]="isBusy()">
      <div class="px-5 py-8 md:px-36 w-full max-w-screen-2xl mx-auto lg:w-2/3">
        <h2 class="text-3xl font-black text-center text-white">Join our newsletter</h2>

        <div class="flex flex-col xl:flex-row flex-wrap xl:flex-nowrap justify-center items-start gap-3 mt-4">
          <div class="w-full lg:min-w-[20rem] lg:max-w-[30rem] lg:flex-grow pt-1 lg:pt-0">
            <app-form-text-field
              [control]="newsletterEmailControl"
              name="newsletterEmail"
              type="email"
              placeholder="Your email"
              background="default"
              height="4rem"
            ></app-form-text-field>

            <app-form-validation-errors
              [control]="newsletterEmailControl"
              [validationErrors]="{ required: 'Email is required', email: 'Email must be a valid email address' }"
            ></app-form-validation-errors>
          </div>

          <div class="flex flex-col md:flex-row items-center w-full xl:min-w-[16rem] xl:max-w-[16rem]">
            <button
              class="white-button px-0 min-h-16 rounded-md w-full flex items-center justify-center"
              [disabled]="(form.invalid && form.pristine) || isSubmitted()"
            >
              @if (isBusy()) {
                <app-spinner></app-spinner>
              } @else {
                <span class="px-4 font-bold">Subscribe to mailing list</span>
              }
            </button>

            @if (isSubscribed() && form.valid && form.pristine) {
              <app-static-notification
                notificationType="success"
                class="md:pl-8 pt-4 md:pt-0 md:min-h-16 flex md:h-full w-full"
              >
                <span>You have been subscribed to the newsletter</span>
              </app-static-notification>
            }
          </div>
        </div>
      </div>
    </fieldset>
  </form>

  <div class="flex justify-center px-5 w-full max-w-screen-2xl mx-auto md:w-1/2">
    <button
      class="focus:outline-none transform hover:scale-110 transition-all duration-300 rounded-full mx-2 flex items-center justify-center"
      (click)="visitPage('facebook')"
      aria-label="Share on Facebook"
      tabindex="0"
    >
      <img src="assets/icons/facebook.svg" alt="Facebook" class="bg-primary" />
    </button>

    <button
      class="focus:outline-none bg-white transform hover:scale-110 transition-all duration-300 rounded-full mx-2 flex items-center justify-center"
      (click)="visitPage('instagram')"
      aria-label="Share on Instagram"
      tabindex="1"
    >
      <img src="assets/icons/instagram.svg" alt="Instagram" class="bg-primary" />
    </button>

    <button
      class="focus:outline-none bg-white transform hover:scale-110 transition-all duration-300 rounded-full mx-2 flex items-center justify-center"
      (click)="visitPage('email')"
      aria-label="Share via Email"
      tabindex="2"
    >
      <img src="assets/icons/email.svg" alt="Email" class="bg-primary" />
    </button>

    <button
      class="focus:outline-none bg-white transform hover:scale-110 transition-all duration-300 rounded-full mx-2 flex items-center justify-center"
      (click)="visitPage('youtube')"
      aria-label="Share on YouTube"
      tabindex="3"
    >
      <img src="assets/icons/youtube.svg" alt="Youtube" class="bg-primary" />
    </button>

    <button
      class="focus:outline-none bg-white transform hover:scale-110 transition-all duration-300 rounded-full mx-2 flex items-center justify-center"
      (click)="visitPage('tiktok')"
      aria-label="Share on TikTok"
      tabindex="4"
    >
      <img src="assets/icons/tiktok.svg" alt="TikTok" class="bg-primary" />
    </button>
  </div>
</section>

import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { catchError, finalize, from, tap } from 'rxjs';

import { BackButtonComponent } from '@components/back-button';
import { SnackBar } from '@components/snack-bar';
import { SpinnerComponent } from '@components/spinner';

import { Booking, defaultEventTitle } from '../../models';
import { BookingsService } from '../../services';
import { AssignTicketsComponent, EventHeaderComponent } from '../../components';

@Component({
  selector: 'app-booking',
  standalone: true,
  imports: [BackButtonComponent, RouterLink, SpinnerComponent, EventHeaderComponent, AssignTicketsComponent],
  templateUrl: './booking.component.html',
  styleUrl: './booking.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingComponent implements OnInit {
  #route = inject(ActivatedRoute);
  protected bookingsService = inject(BookingsService);
  #router = inject(Router);
  #snackBar = inject(SnackBar);
  defaultEventTitle = defaultEventTitle;

  protected booking = signal<Booking | null>(null);
  protected loadingBooking = signal<boolean>(true);

  ngOnInit() {
    const bookingId = this.#route.snapshot.params['id'];
    this.bookingsService
      .getBooking(bookingId)
      .pipe(
        tap((booking) => {
          this.booking.set(booking);
        }),
        catchError(() => {
          this.#snackBar.error('An error occurred retrieving your booking details');
          return from(this.#router.navigate(['/', 'bookings']));
        }),
        finalize(() => this.loadingBooking.set(false)),
      )
      .subscribe();
  }
}

<div class="page">
  <div class="w-full px-2 py-4 md:px-24 md:py-12">
    <div class="flex items-center">
      <a app-back-button [routerLink]="['/', 'profile']" aria-label="Go back to your profile page"></a>
      <h1 class="text-4xl md:text-7xl font-bold">Hi {{ auth.user()?.name }}</h1>
    </div>

    @if (bookings().length > 0) {
      <h2 class="text-2xl font-bold pt-10 md:pt-20">Your booking details can be found below:</h2>

      <div
        class="pt-10 flex flex-col gap-5 md:grid md:auto-rows-fr md:grid-cols-[repeat(auto-fill,minmax(435px,1fr))] md:gap-x-4 md:gap-y-10"
      >
        @for (booking of bookings(); track $index) {
          <app-booking-card [booking]="booking"></app-booking-card>
        }
      </div>
    }

    <div class="flex justify-center py-10 h-10">
      @if (!loadingBookings() && meta()?.current_page !== meta()?.last_page) {
        <button class="filled-button h-6 font-normal" (click)="getNextPage()">Show more</button>
      }

      @if (loadingBookings()) {
        <div class="rounded p-2 w-auto h-max flex items-center justify-center gap-3 bg-white bg-opacity-10">
          <app-spinner [size]="25"></app-spinner>
          <h2 class="text-2xl font-bold">Getting bookings...</h2>
        </div>
      }
    </div>
  </div>
</div>

<div class="h-full max-w-[600px] p-1">
  <div
    class="h-full w-full p-4 bg-neutral-800 text-white rounded-sm md:rounded-lg flex flex-col justify-start border border-neutral-500"
  >
    <div class="flex items-center self-center pb-4">
      <h2 class="text-3xl font-semibold flex-grow pb-3">Oops, out of time!</h2>
    </div>

    <p class="flex text-center">
      Your ticket reservation time has run out and your tickets have gone back into general circulation. Please place
      your order again.
    </p>

    <div class="flex justify-center pt-4">
      <button (click)="dialogRef.close()" class="primary-button justify-self-end">Ok</button>
    </div>
  </div>
</div>

import { inject, Injectable } from '@angular/core';
import { ApiService } from '@features/api';
import { map } from 'rxjs';
import {
  AssignTicketRequest,
  Booking,
  BookingTicket,
  GetBookingsResponse,
  PurchasedTicket,
  UpcomingEvent,
  defaultEventTitle,
} from '../models';

@Injectable({ providedIn: 'root' })
export class BookingsService {
  #api = inject(ApiService);

  getBookings(limit: number, page: number) {
    return this.#api.get<GetBookingsResponse>('/user/bookings', {
      limit,
      page,
    });
  }

  // TODO: Replace this with the correct endpoint once it's done - https://pixelbeard.atlassian.net/browse/BBV2-850
  getUpcomingBooking() {
    return this.#api.get<GetBookingsResponse>('/user/bookings').pipe(
      map((bookings) =>
        bookings.data.map((booking): UpcomingEvent => {
          const eventTime = new Date(booking?.event.date);
          eventTime.setHours(19, 30, 0, 0);
          return {
            name: booking?.event.additional_title ? booking?.event.additional_title : defaultEventTitle,
            date: new Date(booking.event.date),
            start_time: eventTime,
            image: 'assets/mock-images/events/mock-event-1.webp',
            venue_name: booking.event.venue_name,
            venue_address: booking.event.venue_address,
            booking_id: booking.id,
          };
        }),
      ),
      map((bookings) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const sortedBookings = bookings
          .filter((booking) => new Date(booking.date) >= today)
          .sort((b1, b2) => b1.date.getTime() - b2.date.getTime());
        return sortedBookings[0] ?? null;
      }),
    );
  }

  getBooking(bookingId: string) {
    return this.#api.get<Booking>(`/user/bookings/${bookingId}`);
  }

  getTicket(bookingId: string, ticketId: string) {
    return this.#api.get<PurchasedTicket>(`/user/bookings/${bookingId}/tickets/${ticketId}`);
  }

  assignTicket(bookingId: string, ticketId: string, ticket_holder_name: string, ticket_holder_email: string) {
    const url = `/user/bookings/${bookingId}/tickets/${ticketId}`;
    const payload: AssignTicketRequest = { ticket_holder_name, ticket_holder_email };
    return this.#api.post<BookingTicket[]>(url, payload);
  }

  unassignTicket(bookingId: string, ticketId: string) {
    const url = `/user/bookings/${bookingId}/tickets/${ticketId}/remove-holder`;
    return this.#api.delete(url);
  }
}

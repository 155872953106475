import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { finalize } from 'rxjs';

import { BackButtonComponent } from '@components/back-button';
import { SpinnerComponent } from '@components/spinner';
import { AuthService } from '@features/auth';
import { PaginationMeta } from '@features/common';

import { BookingCardComponent } from '../../components';
import { Booking } from '../../models';
import { BookingsService } from '../../services';

@Component({
  selector: 'app-bookings',
  standalone: true,
  imports: [RouterLink, BookingCardComponent, SpinnerComponent, BackButtonComponent],
  templateUrl: './bookings.component.html',
  styleUrl: './bookings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingsComponent {
  auth = inject(AuthService);
  #bookingsService = inject(BookingsService);
  #destroyRef = inject(DestroyRef);

  protected loadingBookings = signal<boolean>(true);

  #limit = 15;
  #currentPage = signal<number>(1);

  protected bookings = signal<Booking[]>([]);
  protected meta = signal<PaginationMeta | null>(null);

  constructor() {
    effect(() => {
      this.#bookingsService
        .getBookings(this.#limit, this.#currentPage())
        .pipe(
          takeUntilDestroyed(this.#destroyRef),
          finalize(() => {
            this.loadingBookings.set(false);
          }),
        )
        .subscribe((newBookings) => {
          const bookingsData = newBookings.data.map(
            (booking): Booking => ({
              ...booking,
              event: {
                ...booking.event,
                date: new Date(booking.event.date),
              },
            }),
          );

          if (this.#currentPage() > 1) {
            this.bookings.update((bookings) => {
              return [...(bookings ?? []), ...bookingsData];
            });
          } else {
            this.bookings.set(bookingsData);
          }

          this.meta.set(newBookings.meta);
        });
    });
  }

  protected getNextPage() {
    this.loadingBookings.set(true);
    this.#currentPage.update((page) => page + 1);
  }
}

<div class="page">
  <div class="w-full px-2 py-4 md:px-24 md:py-12">
    <div class="flex items-center">
      <a app-back-button [routerLink]="['/', 'bookings']" aria-label="Go back to your profile page"></a>
      <h1 class="text-4xl md:text-7xl font-bold">Manage your tickets</h1>
    </div>

    @if (loadingBooking()) {
      <div class="h-[25dvh] md:h-[25vh] w-full flex items-center justify-center gap-2">
        <app-spinner></app-spinner>
        <span>Loading Booking...</span>
      </div>
    }

    @if (booking(); as booking) {
      <div class="pt-8">
        <app-event-header
          [name]="booking.event.additional_title || defaultEventTitle"
          [date]="booking.event.date"
          [venueName]="booking.event.venue_name"
          [address]="booking.event.venue_address"
        ></app-event-header>
      </div>

      <app-assign-tickets [booking]="booking"></app-assign-tickets>
    }
  </div>
</div>
